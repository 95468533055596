html * {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
body {
  font-size: 1rem;
}
body,
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Courier New", serif;
  font-weight: 300;
  line-height: 1.5em;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  color: inherit;
}
h1,
h2,
h3 {
  margin-top: 20px;
  margin-bottom: 10px;
}
h4,
h5,
h6 {
  margin-top: 10px;
  margin-bottom: 10px;
}
h1 {
  font-size: 3.3125rem;
  line-height: 1.15em;
}
h2 {
  font-size: 2.25rem;
  line-height: 1.5em;
}
h3 {
  font-size: 1.5625rem;
  line-height: 1.4em;
}
h4 {
  font-size: 1.125rem;
  line-height: 1.5em;
}
h5 {
  font-size: 1.0625rem;
  line-height: 1.55em;
}
h6 {
  font-size: 0.75rem;
  text-transform: uppercase;
  font-weight: 500;
}
p {
  font-size: 14px;
  margin: 0 0 10px;
  line-height: 1.5em;
}
html {
  font-family: "Courier New", serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -ms-overflow-style: scrollbar;
  -webkit-tap-highlight-color: transparent;
}
body {
  background-color: #f0f0f0;
  color: $black-color;
  margin: 0;
  font-size: 1rem;
  text-align: left;
}

legend {
  border-bottom: 0;
}

// Prevent highlight on mobile
* {
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
  -webkit-tap-highlight-color: transparent;
  box-sizing: border-box;
  &:focus {
    outline: 0;
  }
}

a {
  color: $link-color;
  text-decoration: none;
  background-color: transparent;
  &:hover,
  &:focus {
    color: darken($link-color, 5%);
    text-decoration: none;
  }
}

label {
  font-size: 14px;
  line-height: 1.42857;
  color: $checkboxes-text-color;
  font-weight: 400;
}
small {
  font-size: 75%;
  color: #777;
  font-weight: 400;
}
img {
  vertical-align: middle;
  border-style: none;
}
form {
  margin-bottom: 1.125rem;
}
#Independent {
  font-family: "Courier New" !important;
}
#Dependent {
  font-family: "Courier New" !important;
}
.MuiAutocomplete-option {
  font-family: "Courier New" !important;
}
.MuiButton-root {
  font-family: "Courier New" !important;
}
.MuiFormLabel-root {
  font-family: "Courier New" !important;
}
.MuiTypography-h6 {
  font-family: "Courier New" !important;
}
.MuiDialog-paper {
  border-color: black;
  border-width: "3px";
  border-style: solid;
}

.makeStyles-grid-11 {
  // position: relative !important;
  // flex-basis: auto !important;
  // min-height: 1px !important;
  padding-left: 0px !important;
  padding-right: 0px !important;
  margin-left: 0px !important;
  margin-right: 0px !important;
  // height: 100vh !important;
}

.makeStyles-grid-10 {
  margin-left: 0px !important;
  margin-right: 0px !important;
}

.thecontainer {
  // overflow-y: clip !important;
  background: #2b84ae !important;
  // flex: 1 !important;
  overflow-x: hidden !important;
  overflow-y: hidden;
}

.containerText {
  background: #f0f0f0;
  justify-content: center;
  height: 60vh;
}
// @media only screen and (min-width: 600px) {
//   .thecontainer {
//     overflow-y: clip !important;
//     // min-height: 100% !important;
//   }
// }
// @media only screen and(min-width: 768px) {
//   .thecontainer {
//     overflow-y: clip !important;
//     // min-height: 100% !important;
//   }
// }
@media only screen and(min-width: 960px) {
  .thecontainer {
    overflow-y: scroll !important;
    height: 100vh !important;
  }
  .containerText {
    height: 100vh !important;
  }
  
}
// @media only screen and(min-width: 1200px) {
//   .thecontainer {
//     overflow-y: scroll !important;
//     height: 100vh !important;
//   }
// }


.react-pdf__Page__canvas{
  width: 100% !important;
  height: 100% !important;
}